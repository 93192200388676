import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Caption } from './Caption'

const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Img = styled.img`
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
  max-width: ${props => props.maxWidth};
`

export const TitledImage = ({ src, alt, caption, prefix = 'Rys.', nr, maxWidth }) => (
  <Figure>
    <Img src={src} alt={alt ?? caption} loading='lazy' maxWidth={maxWidth ?? '100%'} />
    <Caption nr={nr} prefix={prefix} text={caption ?? alt} />
  </Figure>
)

TitledImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  caption: PropTypes.string,
}
