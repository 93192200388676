import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

const StyledFigcaption = styled.figcaption`
  color: #73737d;
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding-top: 6px;
  p {
    margin-bottom: 0;
  }
`

export const Caption = ({ text, prefix = 'Rys.', nr }) => {
  const prefixAndNr = nr ? `${prefix} ${nr} ` : ''
  const fullString = `${prefixAndNr}${text}`
  return (
    <StyledFigcaption>
      <ReactMarkdown source={fullString} style={{ marginBottom: 0 }} />
    </StyledFigcaption>
  )
}
